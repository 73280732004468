.title {
  font-size: 16px;
  cursor: pointer;
}

.icons {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  color: var(--neutral-light-5);
}

.contentWrapper {
  padding-bottom: 0;
}
