.nocontentwrapper {
  width: 100%;
  height: 75vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.action {
  width: 350px;
}
