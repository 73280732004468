.layoutWrapper {
  height: 100vh;
  overflow-y: auto;
}

.main,
.sidebar {
  height: 100%;
}

.sidebar {
  background-color: white;
  border-top: 1px solid var(--neutral-dark-5);
}

.sidebar > section {
  overflow-y: scroll;
}

.sidebar > footer {
  height: 40px;
  position: fixed;
  bottom: 0;
}

.sidebar > footer > button {
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  background-color: var(--blue-primary);
  color: #fff;
}

.sidebar > footer > button > p {
  margin-top: 17px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
}

button:disabled {
  color: var(--neutral-light) !important ;
  background-color: var(--neutral-dark-4) !important;
  border-color: var(--neutral-dark-4) !important;
  box-shadow: none;
  cursor: not-allowed !important;
}

@media screen and (min-width: 768px) {
  .layoutWrapper {
    float: left;
    width: 90%;
  }

  .main,
  .sidebar {
    float: left;
  }

  .main {
    width: 72.5%;
  }

  .sidebar {
    width: 27.5%;
    border-top: none;
  }

  .sidebar > section {
    width: 100%;
  }

  .sidebar > footer {
    float: left;

    width: 100%;
    height: 7vh;
  }
}

@media screen and (min-width: 1280px) {
  .layoutWrapper {
    width: 80%;
  }
  .sidebar > section {
    height: calc(100% - 55px);
  }
}

@media screen and (min-width: 1440px) {
  .sidebar > section {
    height: calc(100% - 76px);
  }
}
